const theme = {
  useCustomProperties: true,
  initialColorMode: "light",
  colors: {
    text: "#242121", // Black
    background: "#FBFAF5", // White
    primary: "#292929", // Red
    secondary: "#B8C4BB", // Orange
    muted: "#f8f3e3", // Grey
    accent: "#7b867e",
    modes: {
      dark: {
        text: "#FBFAF5", // White
        background: "#292929", // Jet
        primary: "#FFD900", // Yellow
        secondary: "#fffbbf", // Light yellow
        muted: "#3d3d3d", // Dark Grey
        accent: "#B8C4BB",
      },
    },
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: '"Open Sans", sans-serif',
    subHeading: "Monteserrat, sans-serif",
    monospace: '"Roboto Mono", Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 800,
    bold: 700,
    subHeading: 100,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    logo: {
      color: "primary",
      fontFamily: "heading",
      lineHeight: "heading",
      fontSize: 6,
    },
    h1: {
      color: "primary",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
    },
    h2: {
      color: "primary",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
    },
    h3: {
      color: "text",
      fontFamily: "subHeading",
      lineHeight: "heading",
      fontWeight: "subHeading",
      fontSize: 3,
    },
    h4: {
      color: "text",
      fontFamily: "subHeading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 2,
    },
    h5: {
      color: "text",
      fontFamily: "subHeading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
    },
    h6: {
      color: "text",
      fontFamily: "subHeading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 0,
    },
    p: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "primary",
      textDecoration: "none",
      "&:hover": {
        color: "secondary",
        transition: "color 0.2s ease",
      },
    },
    button: {
      color: "secondary",
      bg: "muted",
      fontWeight: "bold",
      "&:hover": {
        fontWeight: "bold",
        color: "primary",
        transition: "all 0.3s ease",
        transform: "scale(1.025)",
      },
    },
    altButton: {
      bg: "secondary",
      "&:hover": {
        bg: "primary",
        transition: "all 0.3s ease",
      },
    },
    socialIcon: {
      a: {
        svg: {
          position: "relative",
          top: "0",
          fill: "secondary",
        },
        "&:hover": {
          svg: {
            fill: "primary",
            top: "-5px",
            transition: "all 0.2s ease",
          },
        },
      },
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
  },
}

export default theme
